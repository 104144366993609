.gallery-product-img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 340px;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.image-gallery-slide .image-gallery-image {
    min-height: 450px;
    border: 1px solid var(--white-18);
}

.qr-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.qr-row .code-image {
    border: var(--dark-green) 1px solid;
}
