.header-area {
    position: absolute;
    width: 99%;
    background: rgb(0 0 0 / 5%);
}

.ltn__header-middle-area {
    padding-top: 10px;
    padding-bottom: 10px;
}

.logo {
    height: 80px;
    margin: 0px 15px 0 0px;
}

.top-nav {
    justify-content: flex-end;
}

.site-logo {
    flex-wrap: wrap;
    font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
}

.site-title {
    font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
    font-size: 36px;
    color: var(--dark-green);
    font-weight: 600;
    letter-spacing: 1px;
    display: contents;
}

.sticky-active {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.sticky-active .logo {
    height: 55px;
}

.sticky-active .site-logo a {
    font-size: 2.4vw;
    letter-spacing: 1px;
}

.sticky-active .ltn__main-menu > ul > li > a {
    font-size: 18px;
    padding: 2px 5px;
}

.sticky-active .ltn__main-menu li:hover > a {
    color: var(--dark-green);
    background-color: var(--ltn__secondary-color);
    margin: 0px;
    padding: 2px 5px;
    border-radius: 5px;
}

@media (max-width: 991px) {
    .sticky-active {
        position: fixed;
    }
}

@media (max-width: 575px) {
    .site-title {
        font-size: 20px;
    }
    .mobile-menu {
        position: absolute !important;
        top: 26px !important;
        right: 10px !important;
    }
    .mobile-menu .ltn__header-options {
        justify-content: flex-end;
    }
}
