.footer-area-bg {
    background-color: var(--dark-green);
}

.footer-menu {
    display: flex;
    justify-content: center;
}

.code-image {
    width: 150px;
    border-radius: 5%;
}